.box-featured-package {
  background: white;
  border-radius: 0.25rem;
  padding: $grid-gutter-width / 2;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width / 1.5;
  }
  &:hover {
    box-shadow: 0 2px 6px 0 rgba(black, 0.2);
  }
  h4 {
    color: black;
    font-size: 1.2rem;
    margin-bottom: $grid-gutter-width / 5;
  }
  h5 {
    margin: 0 0 $grid-gutter-width;
    font-size: 0.9rem;
  }
  p {
    opacity: .7;
    margin-bottom: $grid-gutter-width / 1.5;
  }
  .price {
    text-align: right;
    font-weight: bold;
    margin: $grid-gutter-width / 1.5 0;
  }
  a {
    display: block;
    text-align: center;
    border: 1px solid $primary;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.075rem;
    padding: 0.5rem;
    transition: all .3s;
    &:hover {
      background-color: $primary;
      color: white;
      text-decoration: none;
    }
  }
}
