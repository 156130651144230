.box-order-card {
  border: 1px solid rgba(black, 0.15);
  border-radius: 0.25rem;
  font-size: 0.9rem;
  .header {
    background-color: rgba(black, 0.15);
    padding: $grid-gutter-width / 2;
  }
  .body {
    background-color: rgba(black, 0.05);
    padding: $grid-gutter-width / 2;
    h5 {
      opacity: 0.7;
    }
  }
  .btn {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.8rem;
    white-space: nowrap;
  }
  .order-status {
    display: inline-block;
  }
  h5 {
    margin: 0 0 5px;
    color: black;
    text-transform: uppercase;
    display: block;
    font-size: 0.8rem;
  }
  & + .box-order-card {
    margin-top: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width / 1.5;
    }
  }
}
