.box-location-overview {

  h2 {
    margin: 0 0 $grid-gutter-width;
    font-size: 1.5rem;
  }

  .tracker-info {
    font-size: 0.9rem;
  }

  .progress {
    margin-bottom: $grid-gutter-width;
  }

  .details {
    border: 1px solid rgba(black, 0.15);
    margin-bottom: $grid-gutter-width;
    border-radius: 0.25rem;
    box-shadow: 0 2px 6px 0 rgba(black, 0.125);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > div {
      width: 35%;
      margin-right: $grid-gutter-width;
      img {
        margin: 0 auto;
        max-width: 90%;
        display: block;
      }
    }
    table {
      margin: $grid-gutter-width 0;
      flex-grow: 1;
      tr {
        & + tr {
          border-top: 1px solid rgba(black, 0.15);
        }
        th {
          text-transform: uppercase;
          opacity: .75;
          font-size: 0.9rem;
          padding: 0.5rem 0.5rem 0.5rem 0;
          font-weight: normal;
          vertical-align: top;
        }
        td {
          padding: 0.5rem 0;
          vertical-align: top;
          font-size: 0.9rem;
        }
      }
    }
  }

  .location {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    border-radius: 0.25rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.125);
  }

  .summary {
    border: 1px solid rgba(black, 0.15);
    margin-bottom: $grid-gutter-width;
    border-radius: 0.25rem;
    box-shadow: 0 2px 6px 0 rgba(black, 0.125);
    .summary-header {
      padding: $grid-gutter-width / 2;
      border-bottom: 1px solid rgba(black, 0.125);
      h3 {
        margin: 0;
        font-size: 1.2rem;
        font-weight: normal;
      }
    }
    .summary-link {
      text-align: center;
      text-transform: uppercase;
      display: block;
      border-top: 1px solid rgba(black, 0.125);
      font-size: 0.8rem;
      padding: $grid-gutter-width / 3 $grid-gutter-width / 2;
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
    .summary-body {
      padding: 20px;
      .progress {
        margin-bottom: 0px;
      }
    }
    table {
      width: 100%;
      tbody {
        tr {
          & + tr {
            border-top: 1px solid rgba(black, 0.125);
          }
        }
        td {
          font-size: 0.9rem;
          padding: $grid-gutter-width / 2;
          @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width / 2 $grid-gutter-width / 1.5;
          }
          a {
            color: $body-color;
            &:hover {
              color: $primary;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .steps {
    .step {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      &:after {
        content: '';
        width: 2px;
        background-color: rgba(black, 0.05);
        left: calc(1.25rem - 1px);
        top: 0;
        bottom: 0;
        position: absolute;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.completed {
        .icon {
          border-color: $primary;
          background-color: $primary;
          color: white;
        }
        &:after {
          background-color: $primary;
        }
      }
      &.in-progress {
        .icon {
          border-color: $primary;
          color: $primary;
        }
      }
      &.in-jeopardy {
        .icon {
          border-color: $danger;
          color: $danger;
        }
      }
      .icon {
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        line-height: calc(2.5rem - 2px);
        border: 1px solid rgba(black, 0.15);
        box-shadow: 0 2px 6px 0 rgba(black, 0.125);
        background-color: white;
        text-align: center;
        position: relative;
        z-index: 1;
        flex-shrink: 0;
      }
      .detail {
        flex-grow: 1;
        margin-left: $grid-gutter-width / 2;
        padding-bottom: $grid-gutter-width;
        p {
          margin: 0;
          font-weight: bold;
        }
        div {
          font-size: 0.8rem;
          opacity: 0.75;
        }
        .date {
          padding-bottom: 0.5em;
          font-weight: bold;
        }
      }
    }
  }

}
