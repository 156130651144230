.box-table {
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  .ReactTable {
    .rt-table {
      .rt-thead {
        &.-header {
          box-shadow: 0 2px 6px 0 rgba(black, 0.125);
          border-bottom: 1px solid rgba(0,0,0,0.1);
          background-color: white;
        }
        .rt-th,
        .rt-td {
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: normal;
          text-align: left;
          padding: 1.5rem 1rem;
          &.-sort-asc {
            box-shadow: inset 0 3px 0 0 rgba(theme-color("primary"), 1);
          }
          &.-sort-desc {
            box-shadow: inset 0 -3px 0 0 rgba(theme-color("primary"), 1);
          }
        }
        .rt-th {
          font-weight: bold;
        }
      }
      .rt-tbody {
        .rt-tr {
          &.-even {
            background-color: white;
          }
        }
        .rt-td {
          padding: 1rem 1rem;
        }
      }
    }
  }
}
