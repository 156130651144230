.modal-address {
  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    background: $body-bg;
    border-bottom-color: darken($body-bg, 5%);

    .modal-title {
      color: black;
      font-size: 1.2rem;
    }
  }

  .modal-footer {
    background: white;
    border: none;

    .btn {
      width: 100%;
      display: block;
    }
  }

  &.medium {
    .modal-dialog {
      max-width: 600px;
    }

    textarea {
      width: 100%;
      min-height: 500px;
      padding-left: 1rem;
      padding-right: 1rem;
      border: none;
      resize: none;
      outline: none;
    }

    .footerContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &.large {
    .modal-dialog {
      max-width: 700px;
    }

    textarea {
      width: 100%;
      min-height: 500px;
      padding-left: 1rem;
      padding-right: 1rem;
      border: none;
      resize: none;
      outline: none;
    }

    .footerContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &.largeWide {
    .modal-dialog {
      max-width: 800px;
    }

    textarea {
      width: 100%;
      min-height: 500px;
      padding-left: 1rem;
      padding-right: 1rem;
      border: none;
      resize: none;
      outline: none;
    }

    .footerContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}