.signature-pad {
  .disclaimer {
    p {
      text-align: center;
      margin: $grid-gutter-width / 2;
    }
  }
  .pad {
    position: relative;
    canvas {
      background-color: white;
      border: 1px solid #ced4da;
      border-radius: 2px;
      margin-bottom: 0;
    }
    .empty {
      position: absolute;
      top: 160px;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
  //    color: $primary;
      opacity: 0.35;
      font-size: 1.8rem;
      pointer-events: none;
      text-transform: uppercase;
    }
  }
  .modal-footer {
    .row {
      width: 100%;
      .col-sm-6 {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .btn {
        display: block;
        width: 100%;
      }
    }
  }
}
