@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

@import 'settings.scss';

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

@import 'components/layout';
@import 'components/loading';
@import 'components/navbar';
@import 'components/buttons';
@import 'components/sidenav';
@import 'components/breadcrumb';
@import 'components/modals-side';
@import 'components/modals';
@import 'components/box-shop-category';
@import 'components/box-featured-package';
@import 'components/box-search-shop';
@import 'components/box-package-detail';
@import 'components/box-shop-categories';
@import 'components/box-product';
@import 'components/box-product-details';
@import 'components/box-tabs';
@import 'components/box-order-card';
@import 'components/box-order-details';
@import 'components/box-quote-detail';
@import 'components/box-table';
@import 'components/box-location-overview';
@import 'components/box-homepage';
@import 'components/form-error';
@import 'components/form-react-select';
@import 'components/form-datepicker';
@import 'components/form-file-upload';
@import 'components/form-sticky-action';
@import 'components/checkbox-dropdown';
@import 'components/steps';
@import 'components/order-status';
@import 'components/react-table';
@import 'components/notifications';
@import 'components/quote-status';
@import 'components/quote-proposal-summary';
@import 'components/quote-items';
@import 'components/signature-pad';
@import 'components/pill-group';
//@import 'components/scroll-box';
//@import 'components/toggle';

@import 'routes/login';
@import 'routes/checkout';
@import 'routes/homepage';
@import 'routes/orders';
@import 'routes/create-password';
@import 'routes/warehouses';


.general-alert {
  color: $primary;
  margin: $grid-gutter-width / 2 0 0;
  padding: 1rem 0.75rem;
  border: 1px solid $primary;
  border-radius: 3px;
  background-color: rgba($primary, 0.1);
  strong {
    text-transform: uppercase;
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.75rem;
  }
  p {
    margin: 0;
    font-size: 0.8rem;
  }
}
.general-alert {
  margin: 0 0 $grid-gutter-width / 2;
  p {
    font-size: 0.9rem;
  }
}
