.box-quote-detail {
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  overflow: hidden;
  position: relative;
  .box-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 6px 0 rgba(black, 0.125);
    & > div {
      padding: $grid-gutter-width / 2 $grid-gutter-width;
      width: 100%;
    }
    strong {
      display: block;
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-bottom: 5px;
      opacity: 0.5;
    }
  }
  .box-body {
    padding: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
    h3 {
      margin-top: 0;
      margin-bottom: $grid-gutter-width / 2;
      font-size: 1.35rem;
      color: black;
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width;
      }
    }
  }
}
