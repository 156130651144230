.layout {
  main {
    padding: $grid-gutter-width 0;
  }
  .layout-subheader {
    font-size: 1.5rem;
    margin-bottom: $grid-gutter-width;
    .quote-status {
      display: inline-block;
      margin-left: $grid-gutter-width / 2;
      font-size: 1rem;
      position: relative;
      top: -2px;
    }
  }
  nav + .layout-subheader  {
    margin-top: $grid-gutter-width / -4;
  }
}
.navbar.fixed-top + .layout {
  padding-top: $layout-top-margin;
}

.layout-header-dropdown {
  text-align: right;
  position: relative;
  z-index: 1;
  .dropdown {
    .dropdown-toggle {
      padding: 0.25rem 0.75rem;
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
    //  left: auto !important;
    //  right: 0;
      .fa {
        margin-right: 0.5rem;
      }
    }
  }
}

.layout-with-sidebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .layout-aside {
    position: fixed;
    left: -100%;
    background-color: $body-bg;
    z-index: 1040;
    width: 90%;
    padding: 0 0 0 $grid-gutter-width / 2;
    transition: all .3s;
    @include media-breakpoint-up(md) {
      position: relative;
      width: 220px;
      left: auto;
      padding: 0;
      z-index: 1;
    }
  }
  .layout-body {
    flex-grow: 1;
    width: 100%;
    @include media-breakpoint-up(md) {
      margin-left: $grid-gutter-width / 2;
    }
  }
}

.mobile-nav-open {
  .layout-with-sidebar {
    .layout-aside {
      left: 0;
      @include media-breakpoint-up(md) {
        left: auto;
      }
    }
  }
}
