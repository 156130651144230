.checkbox-dropdown{
  .dropdown-menu {
    font-size: 0.9em;
    .dropdown-item {
      cursor: pointer;
      padding: 0.25rem 1rem;
      span {
        display: inline-block;
        margin-right: 0.25rem;
        &.fa-toggle-on {
          color: theme-color("success");
        }
        &.fa-toggle-off {
          color: theme-color("danger");
        }
      }
    }
  }
}

.checkbox {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 6px 0 0;
  border-radius: 2px;
  overflow: hidden;
  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    &:checked {
      & + .checkbox-slider {
        // background-color: #ccc;
        background-size: 80% auto;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        &:before {
          transform: translateX(110%);
        }
      }
    }
    &:focus + .checkbox-slider {
      box-shadow: 0 0 1px #ccc;
      outline: none;
      box-shadow: none;
    }
  }
  .checkbox-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: white;
    border: 1px solid #ccc;
    transition: .4s;
    border-radius: 2px;
    padding-left: 2px;
    text-align: center;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0px;
      bottom: 0px;
      background-color: white;
      border-radius: 2px;
      transition: .4s;
    }
    .fa {
      margin: 0;
    }
    .isvg {
      display: block;
      position: relative;
      top: 2px;
      left: 2px;
      svg path {
        fill: white;
      }
    }
  }
}

.inline-checkbox {
  .checkbox {
    vertical-align: middle;
    margin-top: 0;
    & + strong {
      margin-left: 10px;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
