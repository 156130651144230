.quote-items {
  margin-bottom: $grid-gutter-width;
  table {
    width: 100%;
    border: 1px solid #e0e7ee;
    margin: 0;
    font-size: 0.8rem;
    thead {
      border-bottom: 1px solid #e0e7ee;
      tr {
        background-color: #cccccc;
        border: none;
        th {
          padding: 0.75rem 0.5rem;
          text-align: left;
          font-size: 0.8rem;
          text-transform: uppercase;
        }
        td {
          font-size: 0.7rem;
          color: rgba($body-color, 0.65);
          text-transform: uppercase;
          padding: 0.25rem 0.5rem;
          .fa {
            font-size: 1rem;
            margin-right: 0.25rem;
            position: relative;
            top: 2px;
            &.fa-times-circle {
              color: $danger;
            }
            &.fa-check-circle {
              color: $success;
            }
          }
          .form-group {
            margin: 0;
          }
          .toggle-group {
            .toggle {
              margin-top: 0;
            }
            .toggle-label {
              margin-top: 2px;
              margin-left: 0;
            }
          }
        }
        &:last-child {
          td {
            padding-bottom: 0.75rem;
          }
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid #e0e7ee;
        background-color: #f8f8f8;
        td {
          padding: 0.75rem 0.5rem !important;
          &.toggle {
            a {
              color: $primary;
              cursor: pointer;
            }
          }
        }
        &.items {
          & > td {
            padding: 0 !important;
          }
        }
        &.active {
          border-color: $primary;
          background-color: #faf8f5;
          & + tr.items {
            border-color: $primary;
          }
        }
      }
    }
    table {
      width: 100%;
      margin: 0;
      border-width: 0 0 1px;
      thead {
        tr {
          th {
            font-size: 0.7rem;
            color: rgba($body-color, 0.75);
            padding: 0.5rem 0.5rem !important;
          }
        }
      }
      tbody {
        tr {
          background-color: white;
          &:first-child {
            td {
              border-top: none;
            }
          }
        }
        .form-group {
          margin: 0;
        }
        .toggle-group {
          .toggle {
            margin-top: 2px;
          }
          .toggle-label {
            margin-left: 0;
          }
        }
      }
    }
  }
}
