.route-checkout,
.route-checkout-shipping,
.route-checkout-payment,
.route-checkout-review,
.route-checkout-complete {

  main {
    padding: 0;
  }

  .checkout-body {
    margin: 0 auto;
    padding-right: $grid-gutter-width / 2;
    max-width: 40rem;
  }

  .steps {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width * 2;
      margin-bottom: $grid-gutter-width * 2;
    }
  }

  .shipping-alert,
  .billing-alert {
    color: $primary;
    margin: $grid-gutter-width / 2 0 0;
    padding: 1rem 0.75rem;
    border: 1px solid $primary;
    border-radius: 3px;
    background-color: rgba($primary, 0.1);
    strong {
      text-transform: uppercase;
      font-size: 0.9rem;
      display: block;
      margin-bottom: 0.75rem;
    }
    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }
  .billing-alert {
    margin: 0 0 $grid-gutter-width / 2;
    p {
      font-size: 0.9rem;
    }
  }

  .checkout-form {
    margin: 0 auto $grid-gutter-width;
    h5 {
      margin: $grid-gutter-width 0 $grid-gutter-width / 2;
      font-size: 1rem;
      text-transform: uppercase;
      color: black;
    }
    .box {
      margin-bottom: $grid-gutter-width;
      background: white;
      border-radius: 0.25rem;
      padding: $grid-gutter-width / 2;
      box-shadow: 0 2px 6px 0 rgba(black, 0.125);
      transition: all .3s;
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width;
      }
      .form-group {
        margin: 0;
      }
      a {
        color: $primary;
        cursor: pointer;
      }
      h5 {
        margin: 0;
        font-size: 1.2rem;
        color: black;
        text-transform: none;
      }
    }
    .btn {
      width: 100%;
      display: block;
    }
    .box-ship-to {
      a {
        cursor: pointer;
        color: $primary;
        font-size: 0.9rem;
        &:hover {
          text-decoration: underline;
        }
      }
      address {
        margin: 0;
      }
      h5 {
        margin: 0 0 $grid-gutter-width / 2;
        text-transform: none;
        font-size: 0.8rem;
      }
      .btn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.8rem;
        width: 100%;
      }
      .actions {
        clear: both;
        margin-top: $grid-gutter-width;
      }
      .address {
        border-radius: 0.25rem;
        border: 1px solid rgba(black, 0.1);
        padding: 0.5rem 1rem;
        margin-top: 0.5rem;
        font-size: 0.9rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: all .3s;
        & > div {
          flex-grow: 1;
        }
        &.active {
          background-color: rgba($primary, 0.1);
          border-color: $primary;
          .fa {
            color: $primary;
            opacity: 1;
          }
        }
        &:hover {
          border-color: $primary;
        }
        .fa {
          font-size: 1.25rem;
          width: 2.5rem;
          opacity: .1;
          transition: all .3s;
          cursor: pointer;
        }
      }
    }
    .box-accordion {
      padding: 0;
      .accordion > div {
        & + div {
          border-top: 1px solid rgba(black, 0.2);
        }
      }
      .btn-link {
        width: 100%;
        padding: $grid-gutter-width / 2;
        color: black;
        font-size: 0.9rem;
        text-align: left;
        text-transform: none;
        letter-spacing: normal;
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width / 2 $grid-gutter-width;
        }
        &:hover {
          text-decoration: none;
          color: $primary;
        }
        &:focus {
          text-decoration: none;
        }
        .fa {
          font-size: 1rem;
          width: 1.5rem;
          opacity: .1;
          transition: all .3s;
          cursor: pointer;
          &.fa-dot-circle {
            color: $primary;
            opacity: 1;
          }
        }
      }
      .collapse {
        padding: 0 $grid-gutter-width / 2;
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width / 2 $grid-gutter-width;
        }
        .form-group {
          margin-bottom: $grid-gutter-width / 2;
        }
      }
    }
  }

  .shopping-cart {
    background-color: #f2ede8;
    height: 100%;
    min-height: calc(100vh - #{$layout-top-margin});
    padding: $grid-gutter-width;

    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width * 2;
    }

    h2 {
      margin: 0;
      font-size: 1.25rem;
      color: black;
    }

    .table-subtotal {
      width: 100%;
      margin-top: $grid-gutter-width;
      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width * 2;
      }
      th {
        text-transform: uppercase;
        opacity: .75;
        font-size: 0.9rem;
        padding: 0.5rem 0;
      }
      td {
        text-align: right;
        font-size: 0.9rem;
      }
      tbody {
        tr {
          &:last-child {
            th, td {
              padding-bottom: 1.25rem;
            }
          }
        }
      }
      tfoot {
        th {
          border-top: 1px solid rgba(black, 0.15);
          opacity: 1;
        }
        td {
          border-top: 1px solid rgba(black, 0.15);
          font-size: 1.25rem;
        }
        th, td {
          padding-top: 1.25rem;
          color: black;
          vertical-align: middle;
        }
      }
    }
    .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: $grid-gutter-width;
      & > div {
        flex-grow: 1;
        strong {
          font-size: 0.9rem;
          & + p {
            margin: 0;
            font-size: 0.8rem;
            opacity: .75;
            line-height: 1.2;
          }
        }
        &:first-child {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          border-radius: 0.5rem;
          height: 4rem;
          width: 4rem;
          flex-grow: 0;
          margin-right: $grid-gutter-width / 2;
          box-shadow: 0 2px 6px 0 rgba(black, 0.125);
          position: relative;
          .qty {
            position: absolute;
            top: -0.75rem;
            right: -0.75rem;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
            background-color: darken(white, 55%);
            text-align: center;
            font-size: 0.8rem;
            color: white;
          }
        }
        &:last-child {
          text-align: right;
          width: 5rem;
          margin-left: $grid-gutter-width / 2;
          font-size: 0.8rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .btn {
            padding: 0;
            text-transform: none;
            font-size: 0.8rem;
            letter-spacing: normal;
            text-align: right;
          }
        }
      }
    }
  }

}

.route-checkout-review {
  .checkout-form {
    .box {
      h4 {
        font-size: 1.2rem;
        color: black;
        margin: 0 0 $grid-gutter-width / 2;
      }
      h5 {
        font-size: 0.9rem;
        color: black;
        text-transform: uppercase;
        margin: $grid-gutter-width 0 $grid-gutter-width / 5;
        opacity: .5;
      }
      .inline-checkbox .checkbox + strong {
        font-weight: normal;
      }
      .billing-alert {
        margin: $grid-gutter-width / 2 0 0;
      }
      .file-import {
        height: 200px;
        margin-top: $grid-gutter-width;
      }
      address {
        margin: 0;
      }
    }
  }
}

.route-checkout-complete {
  .thank-you {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width * 2;
      margin-bottom: $grid-gutter-width * 2;
    }
    h2 {
      margin: 0;
      text-align: center;
      color: black;
      position: relative;
      &:after {
        content: '';
        height: 3px;
        width: 30px;
        background-color: $primary;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 15px);
      }
    }
  }
  .checkout-form {
    .box {
      h4 {
        font-size: 1.2rem;
        color: black;
        margin: 0 0 $grid-gutter-width / 2;
      }
      h5 {
        font-size: 0.9rem;
        color: black;
        text-transform: uppercase;
        margin: 0 0 $grid-gutter-width / 5;
        opacity: .5;
      }
      address {
        margin: $grid-gutter-width 0;
      }
      .row {
        margin-top: $grid-gutter-width;
      }
    }
  }
}
