.quote-proposal-summary {
  margin-bottom: $grid-gutter-width;

  .services {
    clear: both;

    .service {
      border: 1px solid #e0e7ee;
      border-width: 1px 1px 0;
      margin-bottom: $grid-gutter-width;

      &>strong {
        background-color: #cccccc;
        display: block;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
      }
    }
  }

  .sections {
    .section {
      &>strong {
        background-color: #faf8f5;
        display: block;
        border-bottom: 1px solid #e0e7ee;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 0.9rem;
      }
    }
  }

  .items {
    .item {
      border-bottom: 1px solid #e0e7ee;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9rem;
      padding: 0.75rem 1rem;

      &.optional {

        // strong {
        //   opacity: 0.4;
        // }
        // .price {
        //   opacity: 0.4;
        // }
        &.included {
          strong {
            opacity: 1;

            .fa {
              opacity: 1;
            }
          }

          .price {
            opacity: 1;
          }
        }

        .fa-regular {
          font-family: 'Font Awesome 5 Free';
          margin-right: 0.5rem;
        }
      }

      .price {
        text-align: right;
      }

      strong {
        font-weight: normal;

        .form-group {
          margin: 0 0.5rem 0 0;
          display: inline-block;

          .checkbox-slider {
            .fa {
              margin: 0;
            }
          }
        }

        .fa {
          // opacity: 0.4;
          margin-right: 0.5rem;
          color: $primary;
          &.fa-square {
            position: relative;
            &:after {
              content: '';
              background-color: white;
              position: absolute;
              z-index: 1;
              top: 1px;
              left: 1px;
              right: 1px;
              bottom: 2px;
            }
          }
        }
      }
    }
  }

  .table-total {
    width: 100%;
    border: none;

    thead {
      tr {
        background-color: #f5f8fa;
        text-align: center;

        th {
          padding: 0.75rem 0.5rem;
          font-size: 0.9rem;
          color: rgba($body-color, 0.65);
          border-bottom: 1px solid #e0e7ee;
        }
      }
    }

    tbody {
      th {
        padding: 0.75rem 0.5rem;
        font-size: 0.8rem;
        text-align: right;
        text-transform: uppercase;
        color: rgba($body-color, 0.65);
      }

      td {
        padding: 0.75rem 0.5rem;
        font-size: 0.9rem;
        text-align: right;
      }

      tr:last-child {
        th {
          padding: 0.75rem 0.5rem;
          font-weight: bold;
          color: black;
        }

        td {
          padding: 0.75rem 0.5rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  &.form {
    .items {
      .item {
        &.optional {
          strong {
            padding-left: 0;
            .fa.fa-square {
              position: relative;
              &:after {
                content: '';
                background-color: white;
                position: absolute;
                z-index: 1;
                top: 1px;
                left: 1px;
                right: 1px;
                bottom: 2px;
              }
            }
          }
        }

        strong {
          padding-left: 1.9rem;
        }
      }
    }
  }

}
