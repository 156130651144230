.form-sticky-actions {
  padding: $grid-gutter-width;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e0e7ee;
  background-color: white;
  box-shadow: 0 -2px 6px 0 rgba(black, 0.125);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .actions {
    text-align: center;
    .btn {
      white-space: nowrap;
    }
  }
  .revision {
    max-width: 90%;
    h5 {
      margin-top: 0;
      font-weight: bold;
    }
    p {
      font-size: 0.9rem;
    }
  }
  .accept {
    .form-group {
      strong {
        font-weight: normal;
        font-size: 0.9rem;
        padding-left: 1.5rem;
      }
      .checkbox {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
