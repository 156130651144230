.box-homepage {
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  position: relative;
  .box-header {
    padding: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width / 1.5;
    }
    border-bottom: 1px solid rgba(black, 0.125);
    h3 {
      margin: 0;
      font-size: 1.25rem;
      font-weight: normal;
    }
  }
  .box-footer-link,
  a.box-footer-link {
    text-align: center;
    text-transform: uppercase;
    display: block;
    border-top: 1px solid rgba(black, 0.125);
    font-size: 0.8rem;
    padding: $grid-gutter-width / 3 $grid-gutter-width / 2;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
  .box-body {
    padding: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width / 1.5;
    }
  }

  &.concept {
    .box-header {
      border: none;
      padding-bottom: 0;
    }
    .box-body {
      min-height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin: $grid-gutter-width / 2 auto;
        max-width: 80%;
        display: block;
      }
    }
    h2 {
      margin: 10px 0 0;
      font-size: 0.8rem;
      padding: 0 $grid-gutter-width / 2;
      @include media-breakpoint-up(lg) {
        padding: 0 $grid-gutter-width / 1.5;
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      opacity: 0.25;
      width: 8%;
      @include media-breakpoint-up(lg) {
        width: 15%;
      }
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
    }
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
    }
    .carousel-indicators {
      display: none;
    }
  }

  &.location {
    .box-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .address,
      a {
        display: inline-block;
        color: $primary;
        text-transform: uppercase;
        font-size: 0.9rem;
      }
    }
    .box-body {
      padding: 0;
    }
  }

  &.quotes {
    .box-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .btn {
        font-size: 0.8rem;
        margin: -5px 0;
      }
    }
    .box-body {
      padding: 0;
      min-height: 207px;
      table {
        width: 100%;
        tbody {
          tr {
            & + tr {
              border-top: 1px solid rgba(black, 0.125);
            }
          }
          td {
            font-size: 0.9rem;
            padding: $grid-gutter-width / 2;
            @include media-breakpoint-up(lg) {
              padding: $grid-gutter-width / 2 $grid-gutter-width / 1.5;
            }
            a {
              color: $body-color;
              &:hover {
                color: $primary;
                text-decoration: none;
              }
            }
          }
        }
      }
      &.empty {
        text-align: center;
        padding: $grid-gutter-width / 2 0;
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width / 1.5 0;
        }
        h5 {
          text-transform: uppercase;
          font-size: 0.8rem;
          opacity: 0.6;
          margin-top: $grid-gutter-width / 1.5;
        }
        p {
          font-size: 0.8rem;
          max-width: 90%;
          opacity: 0.6;
          margin: 0 auto $grid-gutter-width / 2;
        }
      }
    }
  }

  &.orders,
  &.documents {
    .box-body {
      padding: 0;
      table {
        width: 100%;
        tbody {
          tr {
            & + tr {
              border-top: 1px solid rgba(black, 0.125);
            }
          }
          td {
            font-size: 0.9rem;
            padding: $grid-gutter-width / 2;
            @include media-breakpoint-up(lg) {
              padding: $grid-gutter-width / 2 $grid-gutter-width / 1.5;
            }
            a {
              color: $body-color;
              &:hover {
                color: $primary;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  &.cart {
    .box-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .btn {
        font-size: 0.8rem;
        margin: -5px 0;
      }
    }
    .box-body {
      min-height: 207px;
      padding-left: 0;
      padding-right: 0;
      p {
        padding: 0 $grid-gutter-width / 2;
        @include media-breakpoint-up(lg) {
          padding: 0 $grid-gutter-width / 1.5;
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        opacity: 0.25;
        width: 8%;
        @include media-breakpoint-up(lg) {
          width: 15%;
        }
      }
      .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
      }
      .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
      }
      .carousel-indicators {
        display: none;
      }
      .product {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $grid-gutter-width / 2 $grid-gutter-width * 1.5;
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width / 2 $grid-gutter-width * 2;
        }
        .image {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 4rem;
          width: 4rem;
          flex-grow: 0;
          margin-right: $grid-gutter-width;
          position: relative;
          .qty {
            position: absolute;
            top: -0.75rem;
            right: -0.75rem;
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
            background-color: darken(white, 55%);
            text-align: center;
            font-size: 0.8rem;
            color: white;
          }
        }
        .detail {
          flex-grow: 1;
          strong {
            font-size: 0.9rem;
          }
          .category {
            margin: 0;
            font-size: 0.8rem;
            opacity: .75;
            line-height: 1.2;
          }
          .price {
            margin-top: $grid-gutter-width / 3;
            font-size: 0.8rem;
          }
        }
      }
      .empty {
        text-align: center;
        font-size: 0.8rem;
        max-width: 90%;
        opacity: 0.6;
        margin: $grid-gutter-width * 2.5 auto;
      }
    }
  }

  &.tracker {
    .box-body {
      min-height: 207px;
      .tracker-info {
        margin-bottom: $grid-gutter-width / 4;
        font-size: 0.9rem;
      }
      .row {
        margin-top: $grid-gutter-width / 2;
        color: rgba(black, .45);
        font-size: 0.9rem;
      }
      .address {
        text-transform: uppercase;
        color: $body-color;
      }
    }
    .box-search-shop {
      .change-shop .form-group .form-label:before {
        background-color: white;
      }
      .react-select__control {
        min-height: 3rem;
      }
    }
    .empty {
      text-align: center;
      font-size: 0.8rem;
      max-width: 90%;
      opacity: 0.6;
      margin: $grid-gutter-width * 2.5 auto;
    }
  }

  &.packages {
    .box-body {
      padding-left: 0;
      padding-right: 0;
      .carousel-control-prev,
      .carousel-control-next {
        opacity: 0.25;
        width: 8%;
        @include media-breakpoint-up(lg) {
          width: 15%;
        }
      }
      .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
      }
      .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
      }
      .carousel-indicators {
        display: none;
      }
      .carousel-item {
        min-height: 168px;
      }
      .package {
        padding: 0 $grid-gutter-width * 1.5;
        @include media-breakpoint-up(lg) {
          padding: 0 $grid-gutter-width * 2;
        }
        strong {
          display: block;
          font-size: 1.2rem;
        }
        p {
          opacity: .6;
          font-size: 0.9rem;
        }
        .products {
          display: block;
          line-height: 1;
          font-size: 0.8rem;
          margin: 0 0 $grid-gutter-width / 2;
        }
        .price {
          display: block;
          color: black;
          font-weight: bold;
        }
      }
      .empty {
        text-align: center;
        font-size: 0.8rem;
        max-width: 90%;
        opacity: 0.6;
        margin: $grid-gutter-width * 2.5 auto;
      }
    }
  }

}
