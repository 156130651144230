.file-upload {
  .btn-file-upload {
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.file-import {
  border: 2px dashed rgba(black, 0.1);
  border-radius: 0.25rem;
  background-color: rgba(black, 0.025);
  text-align: center;
  padding: $grid-gutter-width;
  height: 300px;
  position: relative;
  cursor: pointer;
  .form-container {
    position: static;
  }
  .file-upload {
    .fa {
      font-size: 2rem;
      display: block;
      margin: 1rem 0;
    }
    p {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}
