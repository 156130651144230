.box-shop-categories {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-start;
  }

  .categories {
    background: white;
    border-radius: 0.25rem;
    padding: $grid-gutter-width / 2;
    box-shadow: 0 2px 6px 0 rgba(black, 0.1);
    margin-bottom: $grid-gutter-width;
    transition: all .3s;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 22%;
      margin-right: $grid-gutter-width;
    }
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
    h3 {
      margin: 0 0 $grid-gutter-width / 2;
      color: black;
      font-weight: bold;
      font-size: 0.8rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        a {
          color: $body-color;
          transition: all .3s;
          font-size: 0.9rem;
          &:hover {
            color: $primary;
            text-decoration: none;
          }
          &.active {
            a {
              color: $primary;
            }
          }
        }
        & + li {
          margin-top: $grid-gutter-width / 2;
        }
      }
    }
  }

  .row {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
    }
  }

}
