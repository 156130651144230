.side-nav {
  height: 100%;
  min-height: calc(100vh - #{$layout-top-margin});
  border-right: 1px solid #c9cccf;
  margin-left: $grid-gutter-width / -2;
  nav {
    padding: $grid-gutter-width 0;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li > a {
        display: block;
        color: $body-color;
        transition: all .3s;
        font-size: 1.1rem;
        padding: $grid-gutter-width / 5 $grid-gutter-width / 2;
        .fa, .fal, .fas {
          margin-right: $grid-gutter-width / 5;
        }
        &:hover {
          color: $primary;
          text-decoration: none;
          box-shadow: 3px 0 0 0 $primary inset;
        }
      }
      li {
        &.active {
          & > a {
            color: $primary;
            box-shadow: 3px 0 0 0 $primary inset;
          }
        }
        &.mobile {
          padding: $grid-gutter-width / 5 $grid-gutter-width / 2;
          @include media-breakpoint-up(sm) {
            display: none;
          }
          &.user {
            vertical-align: middle;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: black;
            @include media-breakpoint-up(sm) {
              display: none;
            }
            span {
              vertical-align: middle;
            }
            & > div {
              & + div {
                margin-left: $grid-gutter-width;
              }
            }
            .fa, .fal, .far {
              margin-right: 0.5rem;
              font-size: 1.5rem;
            }
            .btn {
              margin-left: $grid-gutter-width;
              margin-top: -0.375rem;
              margin-bottom: -0.375rem;
              padding-left: 1rem;
              padding-right: 1rem;
            }
            .cart {
              a {
                cursor: pointer;
                transition: all .3s;
                &:hover {
                  text-decoration: none;
                  color: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
}
