.route-login {
  .row {
    min-height: 100vh;
  }
  form {
    background: white;
    border-radius: 0.5rem;
    padding: $grid-gutter-width / 2;
    box-shadow: 0 2px 6px 0 rgba(black, 0.15);
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
  }
  .brand {
    width: 260px;
    height: 40px;
    display: block;
    margin: $grid-gutter-width auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('images/concept-services-logo.svg');
  }
}
