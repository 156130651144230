.btn {
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.075rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-primary {
  color: white;
}
.btn-outline-primary {
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: white !important;
    background-color: $primary;
  }
}
