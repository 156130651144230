.box-search-shop {
  .change-shop {
    .form-group {
      position: relative;
      .form-label {
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 10px;
        font-size: 0.8rem;
        &:before {
          content: '';
          position: absolute;
          left: -2px;
          right: -2px;
          top: 0;
          height: 12px;
          background-color: $body-bg;
          z-index: -1;
        }
      }
    }
  }
  .form-control {
    height: 3.5rem;
    position: relative;
  }
  .react-select__control {
    min-height: 3.5rem;
  }
  .react-select__menu {
    z-index: 3;
  }
}
