.box-tabs {
  background: white;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  .nav-tabs {
    .nav-item {
      border: none;
      padding: 0.75rem 0;
      background-color: transparent;
      font-size: 0.9rem;
      text-transform: uppercase;
      color: $body-color;
      border-radius: 0;
      display: inline-block;
      &:first-child {
        margin-left: $grid-gutter-width / 2;
        @include media-breakpoint-up(lg) {
          margin-left: $grid-gutter-width;
        }
      }
      &.active {
        color: black;
        box-shadow: 0 -3px 0 $primary inset;
      }
      & + .nav-item {
        margin-left: $grid-gutter-width / 2;
        @include media-breakpoint-up(lg) {
          margin-left: $grid-gutter-width / 1.5;
        }
      }
    }
  }
  .tab-content {
    padding: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
  }

  .tabs-pills {
    .nav-tabs {
      border: none;
      display: inline-block;
      background-color: rgba(black, 0.15);
      border-radius: 2rem;
      .nav-item {
        margin-left: 0;
        padding: 0.5rem 1rem;
        &.active {
          box-shadow: none;
          background-color: $primary;
          border-radius: 2rem;
          color: white;
        }
      }
    }
    .tab-content {
      padding: $grid-gutter-width 0 0;
    }
  }
}
