.box-product-details {
  background: white;
  border-radius: 0.25rem;
  padding: $grid-gutter-width / 2;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  overflow: hidden;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width;
  }

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 1.2rem;
    color: black;
  }

  form {
    .form-group {
      position: relative;
      .form-label {
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 10px;
        font-size: 0.8rem;
        &:before {
          content: '';
          position: absolute;
          left: -2px;
          right: -2px;
          top: 0;
          height: 12px;
          background-color: $body-bg;
          z-index: -1;
        }
      }
    }
    .btn {
      width: 100%;
    }
  }

  .back {
    margin-top: 5px;
    font-size: 0.9rem;
  }
  .product-price {
    font-size: 1.5rem;
    margin: $grid-gutter-width / 1.5 0 $grid-gutter-width;
  }

  .contacts {
    margin: $grid-gutter-width 0;
    padding-top: $grid-gutter-width;
    border-top: 1px solid rgba(black, 0.2);
    text-align: center;
    h5 {
      font-size: 0.8rem;
      font-weight: bold;
      margin: 0;
    }
    p {
      font-size: 0.8rem;
      margin-bottom: $grid-gutter-width;
    }
    .row {
      .col {
        &:first-child {
          padding-right: 5px;
        }
        &:last-child {
          padding-left: 5px;
        }
      }
    }
    .btn {
      font-size: 0.8rem;
      text-transform: none;
      width: 100%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      letter-spacing: normal;
    }
  }

  .accordion {
    & > div {
      & + div {
        border-top: 1px solid rgba(black, 0.2);
      }
    }
    .btn-link {
      width: 100%;
      padding: 1rem 0;
      color: black;
      font-size: 1.1rem;
      text-align: left;
      text-transform: none;
      letter-spacing: normal;
      position: relative;
      z-index: 2;
      &:hover {
        text-decoration: none;
        color: $primary;
      }
      &:focus {
        text-decoration: none;
        box-shadow: none;
      }
    }
    .table {
      margin-bottom: $grid-gutter-width;
      font-size: 0.8rem;
      th {
        color: black;
        padding: 0.75rem 1.25rem;
        background-color: rgba(black, 0.05);
      }
      td {
        padding: 0.75rem 1.25rem;
      }
    }
    ul {
      padding: 0;
      margin: 0 0 $grid-gutter-width;
      list-style: none;
      li + li {
        margin-top: 0.5rem;
      }
      span {
        margin-right: 0.35rem;
      }
    }
  }

  .product-description {
    font-size: 0.9rem;
    position: relative;
    margin-bottom: $grid-gutter-width;
    &.open {
      .description {
        max-height: none;
        overflow: visible;
      }
    }
    .description {
      max-height: 155px;
      overflow: hidden;
    }
    .truncate {
      padding-top: $grid-gutter-width * 2.5;
      background: linear-gradient(to top, rgba(255,255,255,1) 30%,rgba(255,255,255,0) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      a {
        color: $primary;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .carousel {

  }

}
