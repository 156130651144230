.pill-group {
    margin-bottom: $grid-gutter-width;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .pill {
        border-radius: 0.25rem;
        border: 1px solid $input-border-color;
        padding: 0.35rem 0.75rem;
        padding-right: 0.1rem;
        margin-bottom: 0.35rem;
        margin-right: 0.2rem !important;
        display: inline-block;
        font-size: 0.7rem;
        color: black;

        .fa {
            margin-left: 0.5rem;
            position: relative;
            top: 1px;
            color: $danger;
        }
    }
}