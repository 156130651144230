.breadcrumb {
  background-color: transparent;
  padding: 0;
  .breadcrumb-item {
    font-size: 0.8rem;
    a {
      color: $body-color;
      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }
  }
}
