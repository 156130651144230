.box-package-detail {
  background: white;
  border-radius: 0.25rem;
  padding: $grid-gutter-width / 2;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width;
  }
  h2 {
    margin: 0;
    color: black;
    font-size: 1.6rem;
  }
  h3 {
    margin: 0 0 $grid-gutter-width / 1.5;
    color: black;
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
  }
  h4 {
    margin: $grid-gutter-width * 2 0 $grid-gutter-width / 1.5;
    color: black;
    font-size: 1.2rem;
  }
  .back {
    margin-top: 5px;
    font-size: 0.9rem;
  }
  .package-products {
    font-size: 0.9rem;
    margin: $grid-gutter-width / 1.5 0;
  }
  .package-price {
    font-size: 1.5rem;
    margin: $grid-gutter-width / 1.5 0 $grid-gutter-width / 2;
  }
  .package-description {
    font-size: 0.9rem;
    position: relative;
    &.open {
      .description {
        max-height: none;
        overflow: visible;
      }
    }
    .description {
      max-height: 155px;
      overflow: hidden;
    }
    .truncate {
      padding-top: $grid-gutter-width * 2.5;
      background: linear-gradient(to top, rgba(255,255,255,1) 30%,rgba(255,255,255,0) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      a {
        color: $primary;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .table {
    margin: 0;
    font-size: 0.9rem;
    tbody {
      th, td {

      }
    }
    .image {
      padding-left: 0;
      img {
        width: 100%;
        max-width: 4rem;
      }
    }
    .description {
      vertical-align: top;
      font-size: 0.8rem;
      strong {
        display: block;
      }
      div {
        opacity: 0.65;
      }
    }
    .details {
      vertical-align: middle;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      a {
        white-space: nowrap;
      }
    }
    .qty {
      text-align: center;
      vertical-align: top;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      strong {
        text-transform: uppercase;
        font-size: 0.7rem;
      }
    }
    .price {
      text-align: right;
      vertical-align: middle;
      font-size: 0.8rem;
      white-space: nowrap;
      padding-right: 0;
      div {
        opacity: 0.75;
      }
    }
  }
}
