$icon-font-path:      "../../node_modules/bootstrap/fonts/";
$fa-font-path:        "../../node_modules/@fortawesome/fontawesome-free/webfonts";

$font-family-sans-serif:      "Roboto", Arial, sans-serif;

$body-bg:                     #faf8f5;
$body-color:                  #5c5f62;
$primary:                     #c5a44d;

$layout-top-margin:           58px;
