.steps {
  margin-bottom: $grid-gutter-width;
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      height: 2px;
      width: 100%;
      background-color: rgba(black, 0.1);
      position: absolute;
      bottom: calc(50% - 2px);
      left: 0;
    }
    li {
      text-align: center;
      font-size: 1.25rem;
      position: relative;
      flex-grow: 1;
      &.active {
        color: black;
        &:after {
          content: '';
          height: 3px;
          width: 30px;
          background-color: $primary;
          position: absolute;
          bottom: -10px;
          left: calc(50% - 15px);
        }
      }
      &:first-child {
        flex-grow: 0;
        &:before {
          content: '';
          height: 2px;
          width: 50%;
          background-color: $body-bg;
          position: absolute;
          bottom: calc(50% - 2px);
          left: 0;
        }
      }
      &:last-child {
        flex-grow: 0;
        &:before {
          content: '';
          height: 2px;
          width: 50%;
          background-color: $body-bg;
          position: absolute;
          bottom: calc(50% - 2px);
          right: 0;
        }
      }
      span {
        display: inline-block;
        background-color: $body-bg;
        padding: 0 $grid-gutter-width / 2;
        position: relative;
        z-index: 1;
      }
    }
  }
}
