.modal-side {

  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 480px;
    height: 100%;
    left: auto;
    transform: translate3d(0, 0, 0);
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    background-color: $body-bg;

    &>form {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

    }
  }

  .modal-body {
    flex-grow: 1;
    //  height: 100%;
    padding: $grid-gutter-width;

    h5 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      margin-top: $grid-gutter-width * 1.5;
      margin-bottom: $grid-gutter-width / 2;
    }

    .inline-checkbox {
      strong {
        font-weight: normal;
      }
    }

  }

  .modal-footer {
    padding: $grid-gutter-width / 2 $grid-gutter-width;
    border-top: 1px solid rgba(black, 0.15);
    background-color: rgba(black, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.fade .modal-dialog {
    left: auto;
    right: -480px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  &.fade.show .modal-dialog {
    left: auto;
    right: 0;
  }

  .modal-header {
    border: none;
    border-radius: 0;
    padding: $grid-gutter-width / 2 $grid-gutter-width $grid-gutter-width;

    .modal-title {
      color: black;
    }

    .close {
      padding: $grid-gutter-width / 2 0 $grid-gutter-width / 2 $grid-gutter-width;
      font-size: 2.5rem;
    }
  }

}

.modal-shopping-cart {
  z-index: 2000;

  .modal-content {
    align-items: center;
  }

  .modal-header {
    padding: $grid-gutter-width * 2 $grid-gutter-width $grid-gutter-width;
    max-width: 25rem;
    width: 100%;

    .modal-title {
      color: black;
      width: 3rem;
      line-height: 1.1;
    }
  }

  .cart-concept {
    padding: 0 $grid-gutter-width;
    width: 100%;
    max-width: 25rem;
    color: black;
  }

  .modal-body {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: white;
      border-radius: 0.25rem;
      padding: $grid-gutter-width / 2;
      box-shadow: 0 2px 6px 0 rgba(black, 0.125);

      &>div {
        flex-grow: 1;

        strong {
          font-size: 0.9rem;

          &+p {
            margin: 0;
            font-size: 0.8rem;
            opacity: .75;
            line-height: 1.2;
          }
        }

        &:first-child {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 5rem;
          width: 5rem;
          flex-grow: 0;
          margin-right: $grid-gutter-width / 2;
        }

        &:last-child {
          text-align: right;
          width: 5rem;
          margin-left: $grid-gutter-width / 2;
          font-size: 0.8rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .btn {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            text-transform: none;
            font-size: 0.8rem;
            letter-spacing: normal;
            text-align: right;
          }
        }

        &.detail {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .qty {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        input {
          width: 2rem;
          margin: 0 0.5rem;
          text-align: center;
          border-color: rgba(black, 0.15);
          font-size: 0.8rem;
          border-radius: 0.25rem;
          border-width: 1px;
          padding: 0.25rem;
        }
      }

      &+.product {
        margin-top: 1rem;
      }
    }

    .subtotal {
      margin: $grid-gutter-width 0;
      padding: $grid-gutter-width / 2 0;
      border-top: 1px solid rgba(black, 0.1);
      border-bottom: 1px solid rgba(black, 0.1);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      strong {
        font-size: 0.9rem;
        font-weight: normal;
        text-transform: uppercase;
      }

      div {
        color: black;
        font-size: 1.5rem;
      }
    }

    .spending-lmit-alert {
      margin: $grid-gutter-width 0;
      padding: 1rem 0.75rem;
      border: 1px solid $danger;
      border-radius: 3px;
      color: $danger;
      background-color: rgba($danger, 0.1);

      strong {
        text-transform: uppercase;
        font-size: 0.9rem;
        display: block;
        margin-bottom: 0.75rem;
      }

      p {
        margin: 0;
        font-size: 0.8rem;
      }
    }

    .btn {
      display: block;
      width: 100%;

      &+.btn,
      &+.dropdown {
        margin-top: $grid-gutter-width / 2;
      }

      &.btn-primary {

        &.disabled,
        &:disabled {
          color: white;
        }
      }
    }

    .share-cart.show {
      width: 100%;
      box-shadow: 0 3px 6px 0 rgba(black, 0.125);
      margin-top: 10px;

      &:before {
        border-bottom: 9px solid rgba(0, 0, 0, 0.2);
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: calc(50% - 4px);
        position: absolute;
        top: -8px;
      }

      &:after {
        border-bottom: 8px solid #FFFFFF;
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: calc(50% - 4px);
        position: absolute;
        top: -7px;
      }

      .dropdown-item {
        color: $primary;
        background-color: white;

        span {
          margin-right: 0.75rem;
        }

        &+.dropdown-item {
          margin-top: 0.5rem;
        }

        &:hover{
          background-color: white;
        }
      }
    }

  }

}