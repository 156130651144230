.box-shop-category {
  background: white;
  border-radius: 0.25rem;
  padding: $grid-gutter-width / 2;
  box-shadow: 0 2px 6px 0 rgba(black, 0.1);
  margin-bottom: $grid-gutter-width;
  min-height: 12rem;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 80%;
  transition: all .3s;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width;
  }
  &:hover {
    box-shadow: 0 2px 6px 0 rgba(black, 0.2);
  }
  h4 {
    color: black;
    font-size: 1.2rem;
    margin: 0;
  }
  a {
    font-size: 0.8rem;
  }
}
