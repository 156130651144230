@import '../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker {
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: theme-color("primary");
  }
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
.form-group-datepicker {
  .form-container {
    .form-control {
      padding-right: 40px;
    }
    .fa-calendar {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1.1em;
    }
  }
}
