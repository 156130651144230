.route-homepage {
  .homepage-actions {
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      text-align: right;
    }
    .btn + .btn {
      margin-left: $grid-gutter-width / 2;
    }
  }
}
