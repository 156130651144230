.navbar {
  box-shadow: 0 2px 6px rgba(black, 0.15);
  .navbar-collapse {
    justify-content: flex-end;
  }
  .navbar-brand {
    width: 240px;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('images/concept-services-logo.svg');
  }
  .navbar-toggler {
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .user {
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    span {
      vertical-align: middle;
    }
    & > div {
      & + div {
        margin-left: $grid-gutter-width;
      }
    }
    .fa, .fal, .far {
      margin-right: 0.5rem;
      font-size: 1.5rem;
    }
    .btn {
      margin-left: $grid-gutter-width;
      margin-top: -0.375rem;
      margin-bottom: -0.375rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .cart {
      a {
        cursor: pointer;
        transition: all .3s;
        &:hover {
          text-decoration: none;
          color: $primary;
        }
      }
      &.with-items {
        position: relative;
        a {
          color: $primary;
        }
        .counter {
          background-color: black;
          color: white;
          font-size: 0.65rem;
          border-radius: 50%;
          width: 1.3rem;
          height: 1.3rem;
          line-height: 1.3rem;
          text-align: center;
          top: -6px;
          right: -6px;
          position: absolute;
        }
      }
    }
  }

}
