.box-product {
  background: white;
  border-radius: 0.25rem;
  padding: $grid-gutter-width / 2;
  box-shadow: 0 2px 6px 0 rgba(black, 0.125);
  margin-bottom: $grid-gutter-width;
  transition: all .3s;
  overflow: hidden;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width / 1.5;
  }
  &:hover {
    box-shadow: 0 2px 6px 0 rgba(black, 0.2);
    a {
      top: -35px;
    }
    .btn {
      bottom: 0;
    }
  }
  a {
    display: block;
    color: $body-color;
    transition: all .3s;
    position: relative;
    top: 0;
    &:hover {
      text-decoration: none;
      h4 {
        text-decoration: underline;
      }
    }
  }
  h4 {
    color: black;
    transition: all .3s;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: $grid-gutter-width / 1.5;
  }
  .image {
    display: block;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 15rem;
  }
  .category {
    font-size: 0.8rem;
    opacity: .55;
  }
  .price {
    font-weight: bold;
    font-size: 0.9rem;
    margin: $grid-gutter-width / 1.5 0 0;
  }
  .btn {
    width: 100%;
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    border-radius: 0;
    transition: all .3s;
  }
}
