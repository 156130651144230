.route-warehouse-update {

    h1 {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.6rem;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        font-size: 0.9rem;
        margin-right: 2rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    button {
        text-transform: none;
        margin-right: 1rem;
    }

    .layout-body {
        margin-left: 13rem;
        margin-right: 13rem;
        margin-top: 2rem;
    }
}